<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <router-link :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-outline-secondary">
        <ph-caret-left :size="22" />
      </router-link>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <h4 class="mb-3">{{ $t('company.new_location') }}</h4>
        <form class="mx-form">
          <div class="mx-modal-form">
            <div class="mb-3">
              <div class="mx-modal-form__element">
                <label class="form-label">{{ company.name }}</label>
              </div>
              <div class="mx-modal-form__element" v-for="element in form_elements">
                <label class="form-label">{{ $t(`company.${element}`) }}</label>
                <input type="text" class="form-control" v-model="form[element]">
                <div v-if="errors[element]" class="invalid-feedback">
                  {{ $t(`company.errors.${getErrorWord(errors[element][0])}`) }}
                </div>
              </div>
              <div class="mx-modal-form__element" style="margin-top: 20px;">
                <label class="mx-modal-form-switch">
                  <input type="checkbox" class="mx-modal-form-switch__input" v-model="form.is_main">
                  <span class="mx-modal-form-switch__slider mx-round"></span>
                </label>
                <label class="form-label">{{ $t(`company.main_address`) }}</label>
              </div>
            </div>
            <div class="mb-3 mx-hour-wrapper">
              <div>
                <h4 class="mx-modal-tr__title">{{ $t(`company.opening_hours`) }}</h4>
                <div class="mx-hour" v-for="element in hour_elements">
                  <strong class="mx-hour__title">{{ $t(`company.day_of_week${mobile ? '_short' : ''}.${element}`) }}</strong>
                  {{ element.closed }}
                  <div class="mx-hour__inputs" v-show="form.opening_hours[element].open === '1'">
                    <input type="text" class="form-control mx-hour__inputs-input" v-mask="'99:99'" placeholder="08:30"
                           v-model="form.opening_hours[element].from">
                    <span class="mx-hour__inputs-tr"> - </span>
                    <input type="text" class="form-control mx-hour__inputs-input" v-mask="'99:99'" placeholder="19:30"
                           v-model="form.opening_hours[element].to">
                  </div>
                  <div class="mx-hour__inputs" v-show="form.opening_hours[element].open === '0'">
                    <span class="mx-date-day_of_week__time-closed">{{ $t('company.closed') }}</span>
                  </div>
                  <span @click="form.opening_hours[element].open = closedDay(form.opening_hours[element].open)" class="mx-hour__close">
                    <ph-lock-laminated :size="18"/>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-form-buttons">
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'company.show', params: {id: $route.params.company_id}}" class="btn btn-peepz-default">
              {{ $t('company.abort') }}
            </router-link>
            <button :disabled="button_disabled" @click="save" type="button" class="btn btn-peepz-success">{{ $t('company.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </CabinetPage>
</template>

<script>

import CabinetPage from "@/components/main/CabinetPage";

import {
  PhPlus,
  PhLockLaminated,
  PhCaretLeft
} from "phosphor-vue";

export default {
  components: {CabinetPage, PhPlus, PhLockLaminated, PhCaretLeft},
  data() {
    return {
      mobile: false,
      button_disabled: false,
      preloader: true,
      hour_elements: ['mo', 'di', 'mi', 'do', 'fr', 'sa', 'so'],
      company: {
        'id': null,
        'name': ''
      },
      form_elements: [
        'country',
        'city',
        'postcode',
        'street',
      ],
      form: {
        id: null,
        country: 'Deutschland',
        city: '',
        postcode: '',
        street: '',
        is_main: 0,
        opening_hours: {
          mo: {from: 'null', to: 'null', open: '1'},
          di: {from: 'null', to: 'null', open: '1'},
          mi: {from: 'null', to: 'null', open: '1'},
          do: {from: 'null', to: 'null', open: '1'},
          fr: {from: 'null', to: 'null', open: '1'},
          sa: {from: 'null', to: 'null', open: '1'},
          so: {from: 'null', to: 'null', open: '1'}
        }
      },
      errors: [],
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= '768'
    this.getCompany()
  },
  methods: {
    closedDay(bool) {
      return bool === '1'? '0': '1'
    },

    getCompany() {
      this.$store.dispatch('companies').then(() => {
        const company = this.$store.getters.companies.find(company => company.id === parseInt(this.$route.params.company_id))
        this.company.name = company.company_name
        this.form.company_id = company.id
        this.preloader = false
      })
    },

    save() {
      this.button_disabled = true;
      this.$store.dispatch('addressCreate', this.form).then(() => {
        this.$router.replace({
          name: 'company.show', params: {id: this.form.company_id}
        })
      }).catch(error => {
        this.button_disabled = false;
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
        }
      })
    },
    getErrorWord(str) {
      return str.match(/\b(required|number)\b/g)[0]
    }
  }
}
</script>
